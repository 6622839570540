import React from 'react'
import { useRef } from 'react'
import './Join.css';
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_l7fol7l', 'template_vvauxrk', form.current, 'FIzmbQpk5ffpW11uJ')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="Join" id="join0-us">
        <div className="left-j">
            <hr />
            <div>
                <span className="stroke-text">PRONTO PARA</span>
                <span>SUBIR DE NÍVEL</span>
            </div>
            <div>
                <span className="stroke-text">MUDE SEU CORPO</span>
                <span>CONOSCO?</span>
            </div>
        </div>
        <div className="right-j">

            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Insira o seu endereço de email' />
                <button className='btn btn-j'>Entre agora</button>
            </form>
        </div>
    </div>
  )
}

export default Join